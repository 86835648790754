import { loadEntitiesThunk } from "@entities/Portal/Entity/model";
import { useFilter } from "@features/Portal/Filters";
import { FilterKit } from "@shared/ui/Filters";
import { useDispatch } from "react-redux";
import { getValidParams } from "../lib/getValidParams";

const EntityFilters = () => {
    const dispatch = useDispatch<AppDispatch>();

    const request = (values: any, reset: boolean) => {
        const conditions: any[] = getValidParams(values);

        const parameters: TMultipleCondition = {
            condition_type: "AND",
            conditions: conditions.length > 0 ? conditions : [],
        };
        if (!reset && parameters) {
            dispatch(
                loadEntitiesThunk(
                    parameters.conditions.length > 0 ? { filter: parameters } : {}
                )
            );
        } else {
            dispatch(loadEntitiesThunk({}));
        }
    };

    const initialState = {
        name: null,
    };

    const { onChange, onReset, values, onSubmit } = useFilter(initialState, request);

    return (
        <FilterKit
            onSubmit={onSubmit}
            onChange={onChange}
            onReset={onReset}
            fields={[
                {
                    type: "input",
                    label: "Имя",
                    name: "name",
                    value: values.name,
                },
            ]}
        />
    );
};

export default EntityFilters;
