import { EntityRowAccess } from "@entities/Portal/EntityRow";
import { UserAccess } from "@entities/Portal/User";
import { BizProcTemplateStartButton } from "@features/Portal/BizProcTemplate/BizProcTemplateStartMenu";
import { EntityRowTableActions } from "@features/Portal/CreateEditEntityRow";
import { EntityRowCategorySelect } from "@features/Portal/Entity/EntityRowCategorySelect";
import { EntityRowsFilters } from "@features/Portal/Entity/EntityRowsFilters";
import { EntityRowTableGroupSelect } from "@features/Portal/EntityRow/EntityRowTableGroup";
import { EstimationValues } from "@features/Portal/EstimationFeatures/EstimationValues";
import { PinEntityToNavigation } from "@features/Portal/NavigationRoute/PinEntityToNavigation";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useHeaderLayout } from "@shared/ui/Header";
import { AdditionalEstimations } from "@widgets/Portal/AdditionalEstimations";
import { EntityRowsView } from "@widgets/Portal/Entity/EntityRowsView";
import { EstimationTemplatesWithEditDrawer } from "@widgets/Portal/EstimationWidgets/EstimationTemplatesWithEdit";
import { ViewPatternSelectAndEdit } from "@widgets/Portal/ViewPatternSelectAndEdit";
import { Flex } from "antd";
import { useTablePageErpLoaded } from "./UseTablePageErpLoaded";
import { useTabMode } from "./UseTabMode";
import "./TablePageErp.scss";

interface ITablePageErpLoaded {
    entity: TEntity;
}

const TablePageErpLoaded = ({ entity }: ITablePageErpLoaded) => {
    const { userId, openCloseDrawerWithNavigate } = useTablePageErpLoaded(entity);

    const { tabMode, onChangeTabMode } = useTabMode(entity.id);

    useHeaderLayout({
        title: (
            <>
                <span className="entity-name-in-header">{entity?.name}</span>
                {entity && (
                    <div style={{ marginTop: 4 }}>
                        <UserAccess.AdminAccess>
                            <PinEntityToNavigation
                                entityTableName={entity.table_name}
                                entityId={entity.id}
                                entityName={entity.name}
                            />
                        </UserAccess.AdminAccess>
                    </div>
                )}
            </>
        ),
    });

    const entityId = entity.id;
    return (
        <div style={{ height: "100%" }}>
            <>
                {entity && (
                    <Flex vertical style={{ height: "100%" }}>
                        <Flex
                            style={{ marginBottom: 8, width: "100%" }}
                            align="center"
                            wrap="wrap"
                            gap={8}
                        >
                            <Flex style={{ flex: "0 1 50px" }}>
                                {entity.uuid ===
                                "26ca1b0e-7b24-4880-a52d-80d284be85d4" ? (
                                    <div style={{ flex: "0 1 auto" }}>
                                        <BizProcTemplateStartButton
                                            bizProcTemplateId={12}
                                        />
                                    </div>
                                ) : (
                                    userId && (
                                        <EntityRowAccess.CreateAccess userId={userId!}>
                                            <EntityRowTableActions
                                                entity={entity}
                                                type={"primary"}
                                                label="Создать"
                                                isDropdown={false}
                                                openCloseDrawerWithNavigate={
                                                    openCloseDrawerWithNavigate
                                                }
                                            />
                                        </EntityRowAccess.CreateAccess>
                                    )
                                )}
                            </Flex>
                            <Flex style={{ flex: "1 1 30px" }}>
                                <EntityRowCategorySelect entityId={entityId} />
                            </Flex>
                            <Flex style={{ flex: "20 1 460px" }} wrap="wrap">
                                <Flex style={{ flex: "2 1 30%" }}>
                                    <EntityRowsFilters entityId={entityId} />
                                </Flex>
                                <Flex style={{ flex: "0 0 fit-content" }} align="start">
                                    {tabMode === "table" && (
                                        <EntityRowTableGroupSelect entityId={entityId} />
                                    )}
                                    <ViewPatternSelectAndEdit
                                        entityId={entityId}
                                        tabMode={tabMode}
                                    />
                                    <EstimationTemplatesWithEditDrawer
                                        entityId={entityId}
                                        entityUuid={entity.uuid}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex
                            style={{
                                flex: "0 1 120px",
                                background: "white",
                                width: "100%",
                                padding: 8,
                                borderRadius: 8,
                                overflow: "auto",
                                marginBottom: 10,
                            }}
                        >
                            <EstimationValues entityId={entityId} />
                        </Flex>
                        <IndentContainer
                            defaultValue={10}
                            customStyle={{ flex: "1 1 700px", overflow: "auto" }}
                        >
                            <EntityRowsView
                                tabMode={tabMode}
                                onChangeTabMode={onChangeTabMode}
                                entity={entity}
                                openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                            />
                        </IndentContainer>
                        {tabMode === "table" && (
                            <AdditionalEstimations entityId={entityId} />
                        )}
                    </Flex>
                )}
            </>
        </div>
    );
};

export default TablePageErpLoaded;
