import { bizProcModel } from "@entities/Portal/BizProc";
import { UserAgreementsList } from "@widgets/Portal/DashboardWidgets/UserAgreementsList";
import { Badge, Tabs } from "antd";
import { useSelector } from "react-redux";
import ActionsListWidgets from "./ActionsListWidgets";

const ActionsListMobileWidgets = () => {
    const agreementsCount = useSelector(bizProcModel.selectAgreementsCounter);
    const formsCount = useSelector(bizProcModel.selectFormsCounter);
    return (
        <Tabs
            defaultActiveKey="1"
            type="line"
            size="middle"
            items={[
                {
                    label: (
                        <>
                            Ожидают заполнения
                            {
                                <Badge
                                    offset={[0, 0]}
                                    style={{ boxShadow: "none", marginLeft: 8 }}
                                    showZero={false}
                                    size={"default"}
                                    count={formsCount + agreementsCount}
                                />
                            }
                        </>
                    ),
                    key: "1",
                    children: (
                        <div className="dashboard__actions-list">
                            <ActionsListWidgets
                                containerStyle={{
                                    marginBottom: 10,
                                }}
                            />
                        </div>
                    ),
                },
                {
                    label: (
                        <>
                            Ожидаю действий
                            {
                                <Badge
                                    offset={[0, 0]}
                                    style={{ boxShadow: "none", marginLeft: 8 }}
                                    showZero={false}
                                    size={"default"}
                                    count={agreementsCount}
                                />
                            }
                        </>
                    ),
                    key: "2",
                    children: (
                        <div className="dashboard__actions-list">
                            <UserAgreementsList />
                        </div>
                    ),
                },
            ]}
        />
    );
};

export default ActionsListMobileWidgets;
