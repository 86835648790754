import {
    BizProcTimelineItemChildren,
    BizProcTimelineItemChildrenSkeleton,
} from "@entities/Portal/BizProc";
import { ConfigProvider, Empty, Skeleton, Timeline } from "antd";
import { TimelineItemProps } from "antd/lib";
import { getVisibleTimelineItems } from "../lib";
import "./BizProcHistory.scss";
import BizProcHistoryActions from "./BizProcHistoryActions";

interface IBizProcHistoryList {
    actions: TBizProcAction[];
    loading?: boolean;
    error?: any;
}

const BizProcHistoryList = ({ actions, loading, error }: IBizProcHistoryList) => {
    const visibleItems = getVisibleTimelineItems(actions);
    const windowWidth = window.innerWidth;

    return (
        <div className="biz-proc-history">
            <div className="biz-proc-history__header"></div>
            <div className="biz-proc-history-list">
                {!error ? (
                    <ConfigProvider
                        theme={{
                            components: {
                                Timeline: {
                                    dotBg: "white",
                                    tailColor: "#d6d6d6",
                                    tailWidth: 1,
                                    itemPaddingBottom: 40,
                                    dotBorderWidth: 1,
                                },
                            },
                        }}
                    >
                        <Timeline
                            items={(loading
                                ? (Array.from({
                                      length: windowWidth > 900 ? 3 : 2,
                                  }) as TBizProcAction[])
                                : visibleItems
                            ).map((item: TBizProcAction) => {
                                const timelineItem: TimelineItemProps = {};
                                timelineItem.dot = loading ? (
                                    <Skeleton.Avatar size="small" active={true} />
                                ) : (
                                    <BizProcHistoryActions action={item} />
                                );
                                timelineItem.children = loading ? (
                                    <BizProcTimelineItemChildrenSkeleton />
                                ) : (
                                    <BizProcTimelineItemChildren action={item} />
                                );
                                return timelineItem;
                            })}
                        />
                    </ConfigProvider>
                ) : (
                    <Empty description="История не найдена" />
                )}
            </div>
        </div>
    );
};

export default BizProcHistoryList;
