import {
    TechMapTemplateListItem,
    TechMapTemplateListItemSkeleton,
} from "@entities/Production/TechMapTemplate";
import { useTechMapTemplateList } from "../model";
import { Fragment } from "react";
import TechMapTemplateCreate from "./TechMapTemplateCreate";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";

interface ITechMapTemplateList {}

const TechMapTemplateList = ({}: ITechMapTemplateList) => {
    const { loading, techMapTemplates, onDelete } = useTechMapTemplateList();

    return (
        <div>
            {loading ? (
                <TechMapTemplateListItemSkeleton count={4} />
            ) : (
                <>
                    <IndentContainer>
                        <TechMapTemplateCreate />
                    </IndentContainer>
                    {techMapTemplates.map((template) => {
                        return (
                            <Fragment key={template.id}>
                                <TechMapTemplateListItem
                                    onSubmitDelete={onDelete}
                                    techMapTemplate={template}
                                />
                            </Fragment>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default TechMapTemplateList;
