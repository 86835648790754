import { secondsToDhms } from "@shared/lib/time";
import { useTechMapSpentTime } from "../model";

interface ITechMapSpentTime {
    isPaused?: boolean;
    spentTime: number;
}

const TechMapSpentTime = ({ isPaused, spentTime }: ITechMapSpentTime) => {
    const { time } = useTechMapSpentTime(spentTime, isPaused);

    return (
        <div
            style={{
                width: time > 86400 ? 180 : 150,
                display: "flex",
                justifyContent: "center",
            }}
        >
            {secondsToDhms(time)}
        </div>
    );
};

export default TechMapSpentTime;
