import { Spin } from "antd";
import { useTMOCodes } from "../model";
import TMOCodesItem from "./TMOCodesItem";
import "./TMOCodes.scss";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
interface ITMOCodes {
    operationId: number;
}

const TMOCodes = ({ operationId }: ITMOCodes) => {
    const { codes, results, codeTemplates, loading } = useTMOCodes(operationId);

    return (
        <div className="tmto-codes">
            {loading ? (
                <Spin />
            ) : codes.length === 0 ? (
                <span>Нет доступных QR/Штрих кодов</span>
            ) : (
                codes.map((code) => {
                    const templateCode = codeTemplates.find(
                        (templateCode) => templateCode.id === code.tmt_operation_code_id
                    );

                    const result = results.find(
                        (result) =>
                            result.id === templateCode?.technical_map_template_result_id
                    );
                    return (
                        <IndentContainer key={code.id}>
                            <TMOCodesItem key={code.id} code={code} result={result} />
                        </IndentContainer>
                    );
                })
            )}
        </div>
    );
};

export default TMOCodes;
