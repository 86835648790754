import { bizProcLib } from "@entities/Portal/BizProcTemplate";
import { AnchorPositionType, RelationType } from "react-archer/lib/types";

const getDefaultRelationRule = (targetId: string) => {
    return {
        targetId: targetId,
        targetAnchor: "top" as AnchorPositionType,
        sourceAnchor: "bottom" as AnchorPositionType,
    };
};
export const getSingleDefaultRelationRule = (targetId: string) => {
    return [
        {
            targetId: targetId,
            targetAnchor: "top" as AnchorPositionType,
            sourceAnchor: "bottom" as AnchorPositionType,
        },
    ];
};

export const getParentNextItem = (
    templateItem: TBizProcTemplateAction,
    templateGroupItems: TBizProcTemplateAction[]
) => {
    const index = templateGroupItems.findIndex((item) => item.key === templateItem.key);

    return templateGroupItems?.[index + 1];
};
export const getParentNextRelation = (
    parentNextItem: TBizProcTemplateAction | undefined,
    customEndItemKey?: string
) => {
    if (parentNextItem) {
        return getSingleDefaultRelationRule(parentNextItem.key);
    }

    return getSingleDefaultRelationRule(
        customEndItemKey !== undefined ? customEndItemKey : "end"
    );
};
export const getArcherRelations = (
    templateItem: TBizProcTemplateAction,
    templateGroupItems: TBizProcTemplateAction[],
    parentNextItem: TBizProcTemplateAction | undefined,
    customEndItemKey?: string,
    log?: string
): RelationType[] => {
    if (!templateItem.children[0]) {
        return getParentNextRelation(parentNextItem, customEndItemKey);
    }
    if (bizProcLib.isBizProcWithRowChild(templateItem.action)) {
        return templateItem.children.map((item) => {
            return getDefaultRelationRule(item.key);
        });
    } else {
        return getSingleDefaultRelationRule(templateItem.children[0].key);
    }
};
export const getAgreementRelations = (templateItem: TBizProcTemplateAction) => {
    return templateItem.children.map((item) => {
        return {
            targetId: item.key,
            targetAnchor: "top" as AnchorPositionType,
            sourceAnchor: (item.action === "ApproveBranchAction"
                ? "right"
                : "left") as AnchorPositionType,
        };
    });
};
export const prepareItemChildren = (children: TBizProcTemplateAction[]) => {
    return children.map((item, index, fullArray) => ({
        ...item,
        order: index,
        next_key: fullArray?.[index + 1]?.key ?? null,
    }));
};
