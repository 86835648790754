import { entityRowApi } from "@entities/Portal/EntityRow";
import { ERP_LOCATION_PATTERN } from "@shared/constants";
import { openDrawer } from "@shared/ui/DrawerManager";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useRef } from "react";

export const useEntityRowCodeParse = () => {
    const readedCode = useRef("");
    const parseReadedCode = (code: string) => {
        entityRowApi
            .getEntityRowByCode(code)
            .then((response) => {
                const info = response.data.data;
                openDrawer({
                    id: info.entity_row_id,
                    name: info.entity.table_name,
                    pattern: ERP_LOCATION_PATTERN,
                });
            })
            .catch(() => {
                notificationEmit({
                    type: "error",
                    title: "Не удалось зарузить запись по коду",
                });
            });
    };
    const handler = (ev: KeyboardEvent) => {
        if (ev.code === "AltLeft") {
            readedCode.current += ev.key;
        }
        if (ev.code === "Enter" && readedCode.current.length > 0) {
            parseReadedCode(readedCode.current);
            readedCode.current = "";
        }
    };
    useEffect(() => {
        document.addEventListener("keypress", handler);
        return () => {
            document.removeEventListener("keypress", handler);
        };
    }, []);
};
