import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";
import SelectEntityRowReadValue from "./SelectEntityRowReadValue";

interface ISelectEntityRow<T> {
    multiple?: boolean;
    onlyRead?: boolean;
    placeholder?: string;
    labelKey?: string;
    idKey?: string;
    value: T | null;
    entityInfo: string;
    byUuid?: boolean;
    initialOptions?: any[];
    onlyDefaultReadRender?: boolean;
    onChange: (value: T, entityRow: any) => void;
}

const SelectEntityRow = <T,>({
    multiple,
    onlyRead,
    placeholder,
    value,
    entityInfo,
    labelKey,
    idKey,
    byUuid,
    onlyDefaultReadRender,
    initialOptions,
    onChange,
}: ISelectEntityRow<T>) => {
    const _onChange = (value: T, option: any) => {
        onChange(
            value,
            Array.isArray(option) ? option.map((option) => option.option) : option
        );
    };

    return (
        <PaginatedSelect
            onlyRead={onlyRead}
            multiple={multiple}
            requestUrl={`${apiUrl}/api/items/${byUuid ? `by-uuid/${entityInfo}` : entityInfo}`}
            value={value}
            placeholder={placeholder}
            valueKey={idKey ?? "id"}
            labelKey={labelKey ?? "name"}
            storeName={entityInfo}
            onChangeValue={_onChange}
            customReadValue={
                onlyDefaultReadRender
                    ? undefined
                    : ({ value, options }) => (
                          <SelectEntityRowReadValue
                              value={value}
                              options={options}
                              byUuid={byUuid}
                              entityInfo={entityInfo}
                          />
                      )
            }
            initialOptions={initialOptions ?? []}
        />
    );
};

export default SelectEntityRow;
