import { ExportButton } from "@features/Portal/Action/Export";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Button, Checkbox, Input, Space } from "antd";
import { useCreateUpdateEntity } from "../model";
import "./CreateUpdateEntityForm.scss";

interface ICreateUpdateEntityForm {
    editingEntity?: TEntity | null;
    entityId: number;
    onCancel?: () => void;
    onSave?: () => void;
}
const CreateUpdateEntityForm = ({
    entityId,
    editingEntity,
    onCancel,
    onSave,
}: ICreateUpdateEntityForm) => {
    const {
        entity,
        error,
        loading,
        onChangeName,
        onChangeIsReferenceField,
        onChangeIsGuidebookField,
        onSaveEntity,
        onCancelClick,
        onChangeIsWithCategories,
    } = useCreateUpdateEntity({
        entityId,
        onCancel,
        onSave,
        editingEntity,
    });

    return (
        <>
            <div
                className="entity-inputs"
                style={{
                    display: "grid",
                    gap: "1rem",
                }}
            >
                <div>
                    <FormItem label="Наименование">
                        <>
                            <Input
                                name="name"
                                status={error.data?.["name"] ? "error" : undefined}
                                value={entity?.name}
                                onChange={onChangeName}
                            />
                            {error.data?.["name"] && (
                                <ErrorText text={error.data?.["name"][0]} />
                            )}
                        </>
                    </FormItem>
                </div>
                <div>
                    <FormItem label="Имя таблицы">
                        <>
                            <Input
                                name="table_name"
                                status={error.data?.["table_name"] ? "error" : undefined}
                                value={entity?.table_name}
                                disabled={entityId !== undefined}
                                onChange={onChangeName}
                            />
                            {error.data?.["table_name"] && (
                                <ErrorText text={error.data?.["table_name"][0]} />
                            )}
                        </>
                    </FormItem>
                </div>

                <div>
                    {entity?.id && (
                        <FormItem label="Команды">
                            <Space>
                                <ExportButton
                                    url={`entity/${entity.id}/export`}
                                    filename={entity.name}
                                />
                            </Space>
                        </FormItem>
                    )}
                </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <IndentContainer type={["marginTop"]}>
                    <>
                        <Checkbox
                            onChange={onChangeIsReferenceField}
                            checked={entity?.is_reference}
                            style={{ textWrap: "nowrap" }}
                        >
                            Вспомогательная сущность
                        </Checkbox>

                        <Checkbox
                            onChange={onChangeIsGuidebookField}
                            checked={entity?.is_guidebook}
                            style={{ textWrap: "nowrap" }}
                        >
                            Справочная сущность
                        </Checkbox>
                        <Checkbox
                            onChange={onChangeIsWithCategories}
                            checked={entity?.is_with_categories}
                            style={{ textWrap: "nowrap" }}
                        >
                            Использовать стадии
                        </Checkbox>
                    </>
                </IndentContainer>
            </div>
            <div className="entity-form-actions">
                <IndentContainer type={["marginRight"]}>
                    <Button onClick={onCancelClick}>Отмена</Button>
                </IndentContainer>
                <Button loading={loading} onClick={onSaveEntity} type="primary">
                    Сохранить
                </Button>
            </div>
            {/* <EntityFieldItemList isdivored={isKeyNotUniq} entityUuid={entity?.uuid} /> */}
            {/* <div className="entity-form-actions">
                <IndentContainer type={["marginRight"]}>
                    <Button onClick={onCancelClick}>Отмена</Button>
                </IndentContainer>
                <Button loading={loading} onClick={onSaveEntity} type="primary">
                    Сохранить
                </Button>
            </div> */}
        </>
    );
};

export default CreateUpdateEntityForm;
