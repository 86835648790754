import { Bar } from "@shared/ui/Bar";
import { useTMTBar } from "../model";

interface ITMTBar {}

const TMTBar = ({}: ITMTBar) => {
    const { options, loading, startingLoadingItemsIds, activeItemsIds, onClickItem } =
        useTMTBar();

    return (
        <Bar
            label="Доступные процессы"
            onClick={onClickItem}
            loading={loading}
            loadingValues={startingLoadingItemsIds}
            activeValues={activeItemsIds}
            options={options}
        />
    );
};

export default TMTBar;
