import {
    techMapTemplateLib,
    techMapTemplateModel,
} from "@entities/Production/TechMapTemplate";
import { TMT_OPERATION_LOCATION_PATTERN } from "@shared/constants";
import { openDrawer } from "@shared/ui/DrawerManager";
import { useDispatch } from "react-redux";

export const useTechMapTmplOperations = (tmtId: number | null) => {
    const { loading, tmtOperations } =
        techMapTemplateModel.useTechMapTmplOperationsLoad(tmtId);

    const dispatch = useDispatch<AppDispatch>();
    const onClick = (operation: TTechMapTemplateOperation) => {
        openDrawer({
            pattern: TMT_OPERATION_LOCATION_PATTERN + "/" + tmtId,
            id: operation.id,
            name: "operation",
            additional: {
                tmtId: tmtId ?? "create",
            },
        });
    };
    const onReorderItems = (
        items: (TTechMapTemplateOperation | TTechMapTemplateOperationCreating)[]
    ) => {
        if (tmtId)
            dispatch(
                techMapTemplateModel.reorderTechMapTemplateOperationsThunk(items, tmtId)
            );
    };
    return {
        loading,
        tmtOperations: techMapTemplateLib.reorderOperations(tmtOperations),
        onClick,
        onReorderItems,
    };
};
