import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TextWithLabel } from "@shared/ui/Text";
import { Button, Popconfirm } from "antd";
import { useEntityFieldItem } from "../model/UseEntityFieldItem";
import "./EntityFieldItem.scss";

interface IEntityFieldListItem {
    dragHandleProps: any;

    deleteLoading?: boolean;
    entityField: TEntityField;
    error?: TValidationError;
    onEdit: (field: TEntityField) => void;
    onDelete: (field: TEntityField) => void;
    isColored?: (key: string) => boolean;
}
const EntityFieldListItem = ({
    entityField,
    dragHandleProps,
    deleteLoading,
    onEdit,
    onDelete,
    isColored,
}: IEntityFieldListItem) => {
    const { onDeleteClick, onClickEdit, name, key, datatype } = useEntityFieldItem({
        entityField,
        onEdit,
        onDelete,
    });

    return (
        <div
            {...dragHandleProps}
            className={`entity-field-list-item ${isColored && isColored(key) ? "border-color" : ""}`}
        >
            <div className="entity-field-list-item__text-labels">
                <div className="entity-field-list-item__text-labels__item">
                    <TextWithLabel
                        noMargin
                        // error={error?.data?.['type']}
                        placeholder={"Значение поля"}
                        allowEdit={false}
                        editing={false}
                        label={"Наименование"}
                        text={name}
                    />
                </div>
                <div className="entity-field-list-item__text-labels__item">
                    <TextWithLabel
                        noMargin
                        // error={error?.data?.['name']}
                        placeholder={"Ключ поля"}
                        allowEdit={false}
                        editing={false}
                        label={"Ключ"}
                        text={key}
                    />
                </div>
                <div className="entity-field-list-item__text-labels__item">
                    <TextWithLabel
                        noMargin
                        allowEdit={false}
                        // error={error?.data?.['name']}
                        placeholder={"Тип"}
                        editing={false}
                        label={"Тип"}
                        text={datatype}
                    />
                </div>
            </div>
            {
                <div className="entity-field-item__buttons">
                    <>
                        <Button
                            key={"edit"}
                            onClick={onClickEdit}
                            type="link"
                            icon={<EditOutlined />}
                        />

                        {!entityField.is_system && (
                            <Popconfirm
                                title="Удалить элемент?"
                                okText="Удалить"
                                onConfirm={onDeleteClick}
                                cancelText="Отмена"
                            >
                                <Button
                                    loading={deleteLoading}
                                    type="link"
                                    danger
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        )}
                    </>
                </div>
            }
        </div>
    );
};

export default EntityFieldListItem;
