import { bizProcModel } from "@entities/Portal/BizProc";
import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { entityModel } from "@entities/Portal/Entity";
import { datatypeModel } from "@entities/Portal/Datatype";
import { variableModel } from "@entities/Portal/Variables";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useCreateUpdateVariable = (
    editingVariable: TVariable | null,
    onSuccess?: (variable: TVariable, isCreating?: boolean) => void
) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isOpen, setIsOpen] = useState(false);
    const [variable, setVariable] = useState<TVariable | null>(null);
    const [error, setError] = useState<TValidationError>({});
    const datatypes = useSelector(datatypeModel.selectDatatypes);
    const { loadEntities, entities } = entityModel.useLoadEntities(false);
    const { LoadBizProcs, bizProcs } = bizProcModel.useLoadBizProcs(false);
    const { loadBizProcTemplates, bizProcTemplates } =
        bizProcTemplateModel.useLoadBizProcTemplates(undefined, false);

    const loadItemsByType = (type: TVariableParentType) => {
        if (type === "entity") {
            loadEntities();
        }
        if (type === "biz_proc_template") {
            loadBizProcTemplates();
        }
        if (type === "biz_proc") {
            LoadBizProcs();
        }
    };
    useEffect(() => {
        setVariable(editingVariable);
        if (editingVariable) {
            onSelectParentType(editingVariable.parent_type);
            setIsOpen(true);
            loadItemsByType(editingVariable.parent_type);
        } else {
            setIsOpen(false);
        }
    }, [editingVariable]);

    const onChangeVariableValue = (value: any) => {
        if (variable)
            setVariable({
                ...variable,
                value: value,
            });
    };

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (variable)
            setVariable({
                ...variable,
                [event.target.name]: event.target.value,
            });
    };

    const onChangeType = (datatype: string) => {
        if (variable) {
            setVariable({
                ...variable,
                datatype: datatype,
            });
        }
    };

    const onClose = () => {
        setIsOpen(false);
        setError({});
        dispatch(variableModel.setEditingVariable(null));
    };
    const onOk = () => {
        setError({});
        if (variable) {
            dispatch(
                variable.is_creating
                    ? variableModel.createVariableThunk(variable)
                    : variableModel.updateVariableThunk(variable)
            )
                .then(parseFormResponse)
                .then((data: TResponse<TVariable>) => {
                    onSuccess && onSuccess(variable, variable.is_creating);
                    onClose();
                })
                .catch((error: TValidationError) => {
                    console.log("error", error);
                    setError(error);
                    notificationEmit({
                        error: error,
                        title: error.message ?? "Ошибка",
                        type: "error",
                    });
                });
        }
    };
    const onSelectParentType = (type: TVariableParentType) => {
        loadItemsByType(type);

        if (variable) {
            setVariable({
                ...variable,
                parent_type: type,
                parent_id: undefined,
            });
        }
    };
    const onSelectParentId = (value: number) => {
        if (variable) {
            setVariable({
                ...variable,
                parent_id: value,
            });
        }
    };
    const getItemsByType = (parentType: TVariableParentType) => {
        if (parentType === "biz_proc") return bizProcs;
        if (parentType === "entity") return entities;
        if (parentType === "biz_proc_template") return bizProcTemplates;
        return [];
    };
    const options = (
        variable?.parent_type ? getItemsByType(variable.parent_type) : []
    ).map((item) => {
        return { label: item.name, value: item.id, item };
    });
    const types = datatypes.map((item) => {
        return { label: item.name, value: item.datatype, item };
    });
    return {
        onClose,
        onChangeName,
        onOk,
        onSelectParentType,
        onSelectParentId,
        onChangeType,
        setVariable,
        onChangeVariableValue,

        types,
        options,
        error,
        isOpen,
        variable,
    };
};
