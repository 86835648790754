import { entityModel } from "@entities/Portal/Entity";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useEntityListItem = (initialEntity?: TEntity) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const onDelete = (entity: TEntity) => {
        setDeleteLoading(true);
        dispatch(entityModel.deleteEntityThunk(entity.id)).finally(() => {
            setDeleteLoading(false);
        });
    };

    const moveToEntityTable = () => {
        navigate(`/erp/${initialEntity!!.table_name}`);
    };

    const moveToBizProcTemplates = () => {
        if (initialEntity?.id) navigate(`/bizproces-templates/${initialEntity?.id}`);
    };

    const onSubmitDelete = () => {
        onDelete(initialEntity!!);
    };
    const onEdit = () => {
        navigate(`/entity/${initialEntity!!.id}`);
        //dispatch(entityModel.setEditingEntity(initialEntity!!));
    };

    return {
        deleteLoading,
        moveToBizProcTemplates,
        moveToEntityTable,
        onSubmitDelete,
        onEdit,
    };
};
