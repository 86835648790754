import ActionCard from "./ActionCard";

interface IParallelAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const ParallelAction = ({ onDeleteItem, templateItem }: IParallelAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="silver"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Параллельное выполнение"}
        />
    );
};

export default ParallelAction;
