import { FormInputItem } from "@entities/Portal/Datatype";
import { Flex } from "antd";
import { conditionPreffix } from "../constants";
import CurrentActiveFiltersValueDeleteIcon from "./CurrentActiveFiltersValueDeleteIcon";
import { useCurrentActiveFiltersValues } from "../model";

interface ICurrentActiveFiltersValues {
    onRemoveFiltersByKeys: (removedFiltersKeys: string[]) => void;
}

const CurrentActiveFiltersValues = ({
    onRemoveFiltersByKeys,
}: ICurrentActiveFiltersValues) => {
    const {
        activeFiltersValues,
        mainTheme,
        isOverWidthForCollapseListToBadge,
        maxFiltersCount,
    } = useCurrentActiveFiltersValues();
    return (
        <>
            {isOverWidthForCollapseListToBadge && (
                <Flex gap={4} align="center" wrap="wrap">
                    {activeFiltersValues.slice(0, maxFiltersCount).map((value, index) => {
                        return (
                            <div
                                className="entity-value-filter__saved-filter"
                                style={{ backgroundColor: mainTheme.token.colorPrimary }}
                                key={index}
                            >
                                <CurrentActiveFiltersValueDeleteIcon
                                    action={onRemoveFiltersByKeys}
                                    isFilledIcon={true}
                                    value={value.key}
                                />
                                <Flex className="entity-value-filter__saved-filter-item">
                                    <span style={{ textWrap: "nowrap" }}>
                                        {`${value.name}: ${value?.condition ? conditionPreffix[value?.condition] ?? "" : ""}`}
                                    </span>
                                    {value?.condition === "null" ? (
                                        <span>Незаполнено</span>
                                    ) : value?.condition === "not_null" ? (
                                        <span>Заполнено</span>
                                    ) : (
                                        <FormInputItem
                                            value={value.value}
                                            field={value}
                                            additionalParameters={{
                                                isBetween: value?.condition === "between",
                                            }}
                                            onlyRead={true}
                                            fieldsArray={activeFiltersValues}
                                            datatype={value.datatype}
                                            onChange={() => {}}
                                        />
                                    )}
                                </Flex>
                            </div>
                        );
                    })}
                </Flex>
            )}

            {((!isOverWidthForCollapseListToBadge && activeFiltersValues.length > 0) ||
                activeFiltersValues.length > maxFiltersCount) && (
                <div
                    className="entity-value-filter__saved-filter"
                    style={{
                        background: "var(--primary)",
                        marginLeft: 4,
                        ...(!isOverWidthForCollapseListToBadge
                            ? {
                                  padding: "0 16px 0 0",
                              }
                            : {}),
                    }}
                >
                    <CurrentActiveFiltersValueDeleteIcon
                        action={onRemoveFiltersByKeys}
                        isFilledIcon={true}
                        value={activeFiltersValues
                            .slice(
                                !isOverWidthForCollapseListToBadge ? 0 : maxFiltersCount
                            )
                            .map((field) => field.key)}
                    />
                    <div className="entity-value-filter__saved-filter-item">
                        <span>
                            Еще:
                            {!isOverWidthForCollapseListToBadge
                                ? activeFiltersValues.length
                                : activeFiltersValues.length - maxFiltersCount}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default CurrentActiveFiltersValues;
