import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { getStartedTms, getTm } from "../../api";
import {
    setGetStartedTmLoading,
    setGetStartedTmsLoading,
    setStartedTm,
    setStartedTms,
} from "../TechMapSlice";

const getStartedTms_ = async (userId: number) => {
    try {
        const response = await getStartedTms(userId);

        return response.data.data.data;
    } catch (err) {
        throw err;
    }
};

export const loadStartedTm =
    (tmId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            dispatch(setGetStartedTmLoading(true));
            const response = await getTm(tmId);
            dispatch(setStartedTm(response.data.data));
        } catch (err) {
            throw err;
        }
        dispatch(setGetStartedTmLoading(false));
    };

export const loadStartedTms =
    (userId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setGetStartedTmsLoading(true));
        try {
            const tms = await getStartedTms_(userId);

            dispatch(setStartedTms(tms));
            if (tms.length > 0) {
                dispatch(loadStartedTm(tms[0].id));
            }
        } catch (err) {
            notificationEmit({
                type: "error",
                title: "Не удалось загрузить информацию о запущенных процессах",
            });
        }
        dispatch(setGetStartedTmsLoading(false));
    };
