import { useTMTableWs } from "../model/UseTMTableWs";
import TMTable from "./TMTable";

interface ITMTableGrouped {}

const TMTableGrouped = ({}: ITMTableGrouped) => {
    useTMTableWs();
    return <TMTable level={0} />;
};

export default TMTableGrouped;
