import { Avatar, Badge } from "antd";
import "./UserShortProfile.scss";
import { BellOutlined } from "@ant-design/icons";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { useNavigate } from "react-router-dom";

interface IUserShortProfile {
    user: TUser;
    badges?: any;
    onClick: () => void;
    showNotificationBell?: boolean;
}

const UserShortProfile = ({
    user,
    badges,
    onClick,
    showNotificationBell,
}: IUserShortProfile) => {
    const navigate = useNavigate();
    const avatarName = user.name
        ? user.name
              .toUpperCase()
              .split(" ")
              .map((el: string): string => el[0])
              .join("")
        : "Безымянный";

    const onNotificationBellClick = () => navigate("dashboard");
    return (
        <FlexContainer customStyle={{ alignItems: "center", justifyContent: "flex-end" }}>
            {showNotificationBell && (
                <div onClick={onNotificationBellClick} className="notification-dashboard">
                    <Badge
                        offset={[-10, 10]}
                        size="small"
                        count={badges["/dashboard"] ?? undefined}
                    >
                        <Avatar
                            size={38}
                            className="notification-dashboard__icon"
                            icon={<BellOutlined />}
                        />
                    </Badge>
                </div>
            )}
            <div className="short-profile" onClick={onClick}>
                <div className="short-profile__avatar">
                    <Avatar style={{ color: "black", background: "none" }}>
                        {avatarName}
                    </Avatar>
                </div>

                <div className="short-profile__name">{user.name}</div>
            </div>
        </FlexContainer>
    );
};

export default UserShortProfile;
