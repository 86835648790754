import { TMTResultSelectOption } from "@entities/Production/TechMapTemplate";
import { Code } from "@shared/ui/Code";
import { Button } from "antd";
import "./TMOCodes.scss";
import { printCode } from "@shared/StickerPrinter/emits";
import { useSelector } from "react-redux";
import { techMapModel } from "@entities/Production/TechMap";
interface ITMOCodesItem {
    code: TMOCode;
    result: TTechMapTemplateResult | undefined;
}

const TMOCodesItem = ({ code, result }: ITMOCodesItem) => {
    const printerIsConnected = useSelector(techMapModel.selectPrinterIsConnected);

    const printCodeClick = () => {
        printCode(code.value, "qr");
    };
    return (
        <div className="tmtoc-item">
            <Code
                codeType="qrcode"
                bgColor="white"
                color="black"
                value={code.value}
                showValue
                size={100}
            />

            {result && (
                <TMTResultSelectOption
                    entityUuid={result.entity_uuid}
                    entityRowId={result.entity_row_id}
                />
            )}
            <Button onClick={printCodeClick} disabled={!printerIsConnected}>
                Печать
            </Button>
        </div>
    );
};

export default TMOCodesItem;
