import { DragAndDropList } from "@shared/ui/DragAndDropList";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useSidebarReorder } from "../model/UseSidebarReorder";
import "./Sidebar.scss";
import { SidebarItemEditing } from "./SidebarItemEditing";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { NavigationRouteEditUpdateModal } from "@features/Portal/NavigationRoute/NavigationRouteEditUpdate";
import { useState } from "react";

type SidebarEditingMenuProps = {
    menuItems: ItemType[];
};

const SidebarEditingMenu = ({ menuItems }: SidebarEditingMenuProps) => {
    const { onReorder } = useSidebarReorder();
    const [isOpen, setIsOpen] = useState(false);
    const onCancel = () => {
        setIsOpen(false);
    };
    const onAdd = () => {
        setIsOpen(true);
    };

    return (
        <>
            <DragAndDropList
                disabled={false}
                keyName="key"
                items={
                    menuItems.filter(
                        ({ navigationRoute }: any) =>
                            !(
                                navigationRoute?.key === "admin" ||
                                navigationRoute?.parent_key === "admin" ||
                                navigationRoute?.key === "tables" ||
                                navigationRoute?.parent_key === "tables"
                            )
                    ) as any
                }
                onChangeItems={onReorder}
                draggableItem={(dragHandleProps, route: any, index) => {
                    return (
                        <>
                            <SidebarItemEditing
                                dragHandleProps={dragHandleProps}
                                navigationRoute={route.navigationroute}
                            />
                        </>
                    );
                }}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button icon={<PlusOutlined />} type="link" onClick={onAdd}>
                    Добавить элемент
                </Button>
                <NavigationRouteEditUpdateModal isOpen={isOpen} onCancel={onCancel} />
            </div>
        </>
    );
};

export default SidebarEditingMenu;
