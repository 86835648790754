import { Tooltip } from "antd";
import { getTooltipTitle } from "../lib";
import { useState } from "react";

interface ICapabilitiesRolesTypeInputReadOnly {
    emptyValue: "-" | "Все";
    value: string;
    onFocus?: () => void;
    additionalOptions?: { label: string; value: any }[];
}

const CapabilitiesRolesTypeInputReadOnly = ({
    emptyValue,
    value,
    additionalOptions,
    onFocus,
}: ICapabilitiesRolesTypeInputReadOnly) => {
    const { showMore, tooltipTitle, visibleArrayValue } = getTooltipTitle(
        value,
        additionalOptions
    );
    const [isHover, setIsHover] = useState<boolean>(false);
    return (
        <ul
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={onFocus}
            style={{
                cursor: "pointer",
                margin: 0,
                padding: 0,
                width: "100%",
                height: "100%",
                textAlign: "center",
                verticalAlign: "middle",
            }}
        >
            {value ? (
                <Tooltip
                    title={tooltipTitle}
                    color="var(--primary-5)"
                    open={showMore && isHover}
                >
                    {visibleArrayValue.map(
                        (fieldKey: string, index: number, array: string[]) => (
                            <li
                                key={index}
                                style={{ listStyle: "none", padding: 0, margin: 0 }}
                            >
                                {additionalOptions?.find(
                                    (item) => item.value === fieldKey
                                )?.label ?? fieldKey}
                                {index !== array.length - 1 && ","}
                            </li>
                        )
                    )}
                </Tooltip>
            ) : (
                emptyValue
            )}
        </ul>
    );
};

export default CapabilitiesRolesTypeInputReadOnly;
