import { useState } from "react";
import { getEntityRowsCategories } from "../lib";
import { entityRowApi } from "@entities/Portal/EntityRow";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

export const useEntityRowsActionsChangeStage = (
    entityId: number,
    selectedRows: any[],
    onSuccess?: () => void
) => {
    const [stage, setStage] = useState<string | number | null>(null);
    const [category, setCategory] = useState<string | number | null>(null);
    const [loading, setLoading] = useState(false);
    const categories = getEntityRowsCategories(selectedRows);
    const disabled = selectedRows.length === 0;
    const isSingleCategory = categories.length === 1;

    const onChangeStage = (stage: string | number | null) => {
        setStage(stage);
    };
    const onChangeCategory = (category: string | number | null) => {
        setStage(null);
        setCategory(category);
    };
    const onSubmit = () => {
        const newCategory = isSingleCategory ? categories[0] : category;
        if (!newCategory) {
            notificationEmit({
                type: "error",
                title: "Выберите категорию!",
            });
            return;
        }
        if (!stage) {
            notificationEmit({
                type: "error",
                title: "Выберите стадию!",
            });
            return;
        }
        setLoading(true);
        entityRowApi
            .saveEntityRows({
                entityId,
                items: selectedRows.map((row) => ({
                    ...row,
                    category: newCategory,
                    stage,
                })),
            })
            .then(() => {
                onSuccess && onSuccess();
                setStage(null);
                setCategory(null);
            })
            .catch(() => {
                notificationEmit({
                    type: "error",
                    title: "Не удалось перевести в стадию!",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        stage,
        category,
        categories,
        isSingleCategory,
        disabled,
        loading,
        onChangeStage,
        onChangeCategory,
        onSubmit,
    };
};
