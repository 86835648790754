import { techMapModel } from "@entities/Production/TechMap";
import { useSelector } from "react-redux";

export const useTMOCodes = (operationId: number) => {
    const { operation, loading } = techMapModel.useLoadFullOperation({ operationId });
    const tm = useSelector(techMapModel.selectStartedTm);
    const results = tm?.template.results ?? [];
    const codes = operation?.codes ?? [];
    const codeTemplates = (operation?.template_operation.code_templates ??
        []) as TMTOCodeTemplate[];
    return {
        codes,
        codeTemplates,
        results,
        loading,
    };
};
