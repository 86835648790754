import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    approveTm,
    attachItem,
    detachItem,
    finishTm,
    getExecutableTmts,
    nextOperation,
    pauseOperation,
    resumeOperation,
    startTmt,
    stopTm,
} from "../api";

export const loadExecutableTmtsThunk = createAsyncThunk(
    "TechMaplice/loadExecutableTmtsThunk",
    async (props: { params?: TParams }, { rejectWithValue }) => {
        try {
            const response = await getExecutableTmts();
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const nextOperationThunk = createAsyncThunk(
    "TechMaplice/nextOperationThunk",
    async (props: { tmoId: number }, { rejectWithValue }) => {
        try {
            const response = await nextOperation(props.tmoId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const finishTmThunk = createAsyncThunk(
    "TechMaplice/finishTmThunk",
    async (props: { tmId: number; count?: number }, { rejectWithValue }) => {
        try {
            const response = await finishTm(props.tmId, props.count);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const stopTmThunk = createAsyncThunk(
    "TechMaplice/stopTmThunk",
    async (props: { tmId: number }, { rejectWithValue }) => {
        try {
            const response = await stopTm(props.tmId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const pauseOperationThunk = createAsyncThunk(
    "TechMaplice/pauseOperationThunk",
    async (props: { oId: number }, { rejectWithValue }) => {
        try {
            const response = await pauseOperation(props.oId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const resumeOperationThunk = createAsyncThunk(
    "TechMaplice/resumeOperationThunk",
    async (props: { oId: number }, { rejectWithValue }) => {
        try {
            const response = await resumeOperation(props.oId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const startTmtThunk = createAsyncThunk(
    "TechMaplice/startTmtThunk",
    async (props: { tmtId: number }, { rejectWithValue }) => {
        try {
            const response = await startTmt(props.tmtId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const attachItemThunk = createAsyncThunk(
    "TechMaplice/attachItem",
    async (props: { oId: number; value: string }, { rejectWithValue }) => {
        try {
            const response = await attachItem(props.oId, props.value);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const detachItemThunk = createAsyncThunk(
    "TechMaplice/detachItemThunk",
    async (props: { id: number }, { rejectWithValue }) => {
        try {
            const response = await detachItem(props.id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const approveTmThunk = createAsyncThunk(
    "TechMaplice/approveTmThunk",
    async (
        props: {
            tmtId: number;
            decision: TDecision;
            results?: TTechMapPartlyRejectedResult[];
            level?: string | number;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await approveTm(props.tmtId, props.decision, props.results);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
