import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Select, Skeleton, Spin } from "antd";
import { CSSProperties, ReactNode } from "react";
import { usePaginatedSelect } from "../model";
import PaginatedSelectReadValue from "./PaginatedSelectReadValue";
import "./PaginatedSelectSkeletons.scss";

export interface IPaginatedSelect {
    initialOptions: TPaginatedSelectOption[];
    value: any;
    params?: any;
    selectLoading?: boolean;
    valueToString?: boolean;
    multiple?: boolean;
    onlyRead?: boolean;
    requestUrl: string;
    coloredKey?: string;
    storeName?: string;
    valueKey: string;
    labelKey?: string;
    placeholder?: string;
    readValueTextStyle?: CSSProperties;
    customReadValue?: (props: {
        value: any;
        options: TPaginatedSelectOption[];
    }) => ReactNode;
    onChangeValue: (value: any, option: any) => void;
    smallVersion?: boolean;
    disabled?: boolean;
}

const PaginatedSelect = ({
    initialOptions,
    multiple,
    value,
    params,
    onlyRead,
    valueKey,
    selectLoading,
    storeName,
    coloredKey,
    requestUrl,
    valueToString,
    placeholder,
    labelKey,
    readValueTextStyle,
    onChangeValue,
    customReadValue,
    disabled,
    smallVersion,
}: IPaginatedSelect) => {
    const { loading, searchValue, options, onSearch, loadOnFocus } = usePaginatedSelect({
        initialOptions,
        valueKey,
        value,
        requestUrl,
        labelKey,
        valueToString,
        onlyRead,
        storeName,
        extraParams: params,
        coloredKey,
    });

    return !loading ||
        options.length > 0 ||
        (typeof value === "object" && value?.length === 0) ||
        value === null ||
        value === undefined ? (
        <>
            {onlyRead ? (
                customReadValue ? (
                    customReadValue({
                        value: value,
                        options: options,
                    })
                ) : (
                    <PaginatedSelectReadValue
                        value={value}
                        options={options}
                        coloredKey={coloredKey}
                        readValueTextStyle={readValueTextStyle}
                    />
                )
            ) : (
                <Select
                    dropdownStyle={{
                        zIndex: 1301,
                    }}
                    size={smallVersion ? "small" : undefined}
                    disabled={disabled}
                    allowClear
                    onSearch={onSearch}
                    showSearch
                    placeholder={placeholder}
                    loading={selectLoading}
                    optionFilterProp="children"
                    filterOption={() => true}
                    style={{ width: "100%", minHeight: smallVersion ? 0 : undefined }}
                    onFocus={loadOnFocus}
                    searchValue={searchValue}
                    value={multiple && value && value.length === 0 ? [] : value}
                    onChange={onChangeValue}
                    mode={multiple ? "multiple" : undefined}
                    options={options}
                    dropdownRender={(menu) =>
                        loading ? (
                            <FlexContainer
                                customStyle={{
                                    minHeight: 150,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Spin />
                            </FlexContainer>
                        ) : (
                            <>{menu}</>
                        )
                    }
                />
            )}
        </>
    ) : (
        <div className={`${onlyRead ? "select-value" : `select-skeleton`}`}>
            {typeof value !== "object" ? (
                <Skeleton
                    title={false}
                    paragraph={{ rows: 1, width: 100 + "%" }}
                    style={{
                        fontSize: 5,
                        width: 50,
                    }}
                    active={true}
                />
            ) : (
                value?.map((_: any, index: number) => {
                    return (
                        <Skeleton
                            key={index}
                            title={false}
                            paragraph={{ rows: 1, width: 100 + "%" }}
                            style={{
                                fontSize: 5,
                                width: 50,
                                marginRight: 0.8 + "%",
                                height: 22,
                            }}
                            active={true}
                        />
                    );
                })
            )}
        </div>
    );
};

export default PaginatedSelect;
