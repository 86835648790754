import { PlusOutlined } from "@ant-design/icons";
import { Upload, UploadFile } from "antd";
import React, { memo } from "react";
import { FileIconRender } from "./FileIcons";
import FileItemRender from "./FileItemRender";
import "./FileUpload.scss";
import FileUploadModal from "./FileUploadModal";
import { useFileUpload } from "./model";
export interface IFileUpload {
    uploadUrl: string;
    maxFiles?: number;
    multiple?: boolean;
    beforeUpload?: (file: any) => void;
    initialFileList: UploadFile[];
    onlyRead?: boolean;
    smallFormat?: boolean;
    onChangeFileList: (fileList: any) => void;
    isDisabledTableListHoverActions?: boolean;
}

const FileUpload = ({
    uploadUrl,
    maxFiles,
    multiple,
    smallFormat,
    initialFileList,
    beforeUpload,
    onChangeFileList,
    onlyRead,
    isDisabledTableListHoverActions,
}: IFileUpload) => {
    const {
        uploadFile,
        setPreviewOpen,
        setPreviewImage,
        handleChange,
        handleCancel,
        handlePreview,
        onRemove,
        previewOpen,
        previewImage,
        previewTitle,
        accessToken,
        fileList,
        onPreviewFile,
    } = useFileUpload({
        uploadUrl,
        initialFileList,
        onChangeFileList,
    });

    return (
        <>
            {onlyRead && fileList.length === 0 ? (
                <></>
            ) : (
                <Upload
                    multiple={multiple}
                    listType={smallFormat ? "text" : "picture-card"}
                    customRequest={uploadFile}
                    defaultFileList={initialFileList}
                    onRemove={onRemove}
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                    headers={{
                        authorization: `Bearer ${accessToken}`,
                    }}
                    showUploadList={{
                        showPreviewIcon: false,
                        showRemoveIcon: !onlyRead,
                        showDownloadIcon: false,
                    }}
                    iconRender={(file) => <FileIconRender file={file} />}
                    itemRender={(
                        originNode: React.ReactElement,
                        file: UploadFile,
                        fileList: Array<UploadFile>,
                        actions: {
                            download: () => void;
                            preview: () => void;
                            remove: () => void;
                        }
                    ) => (
                        <FileItemRender
                            handlePreview={handlePreview}
                            onlyRead={onlyRead}
                            file={file}
                            originNode={originNode}
                            actions={actions}
                            onPreviewFile={onPreviewFile}
                            isDisabledTableListHoverActions={
                                isDisabledTableListHoverActions
                            }
                            smallFormat={smallFormat}
                        />
                    )}
                    openFileDialogOnClick
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {onlyRead || fileList.length >= (maxFiles ?? 10) ? null : (
                        <button style={{ border: 0, background: "none" }} type="button">
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Добавить</div>
                        </button>
                    )}
                </Upload>
            )}
            {onlyRead === true && fileList.length === 0 && (
                <span>Файлы не добавлены</span>
            )}
            {previewImage && (
                <FileUploadModal
                    setPreviewImage={setPreviewImage}
                    previewOpen={previewOpen}
                    previewImage={previewImage}
                    previewTitle={previewTitle}
                    handleCancel={handleCancel}
                    setPreviewOpen={setPreviewOpen}
                />
            )}
        </>
    );
};

export default memo(FileUpload, (prevProps, nextProps) => {
    const prevFileList = prevProps.initialFileList;
    const nextFileList = nextProps.initialFileList;

    if (prevFileList.length === nextFileList.length) {
        for (let index = 0; index < prevFileList.length; index++) {
            if (prevFileList[index].url !== nextFileList[index].url) {
                return false;
            }
        }
    }
    return false;
});
