import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import {
    approveTmThunk,
    attachItemThunk,
    detachItemThunk,
    finishTmThunk,
    loadExecutableTmtsThunk,
    nextOperationThunk,
    pauseOperationThunk,
    resumeOperationThunk,
    startTmtThunk,
    stopTmThunk,
} from "./TechMapApiThunk";
import { ITechMap } from "./TechMapSlice";

const executableTmtsLoadBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(loadExecutableTmtsThunk.fulfilled, (state, action) => {
        state.executableTmts = action.payload.data;

        state.getExecutableLoading = false;
    });
    builder.addCase(loadExecutableTmtsThunk.pending, (state, action) => {
        state.getExecutableLoading = true;
    });
    builder.addCase(loadExecutableTmtsThunk.rejected, (state, rejectedValue) => {
        state.getExecutableLoading = false;
        notificationEmit({
            title: "Не удалось загрузить доступные шаблоны",
            type: "error",
        });
    });
};

const startTmtBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(startTmtThunk.fulfilled, (state, action) => {
        state.startedTm = action.payload.data;
        state.startedTms.unshift(action.payload.data);
        state.startingLoading = null;
    });
    builder.addCase(startTmtThunk.pending, (state, action) => {
        state.startingLoading = action.meta.arg.tmtId;
    });
    builder.addCase(startTmtThunk.rejected, (state, rejectedValue) => {
        state.startingLoading = null;
        console.log("rejectedValue.payload ", rejectedValue.payload);
        const errMessage = (rejectedValue.payload as any)?.data?.data
            ? (Object.values((rejectedValue.payload as any)?.data?.data)?.[0] as
                  | string
                  | undefined)
            : null;
        notificationEmit({
            title: errMessage ?? "Не удалось запустить",
            type: "error",
        });
    });
};
const finishTmBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(finishTmThunk.fulfilled, (state, action) => {
        state.startedTm = null;
        state.startedOperation = null;
        state.startedTms = state.startedTms.filter(
            (item) => item.id !== action.meta.arg.tmId
        );
        state.nextOperationLoading = false;
        notificationEmit({
            type: "success",
            title: "Процесс успешно завершен!",
            description:
                action.payload.data.status === "waiting"
                    ? "Он будет ожидать одобрения от мастера!"
                    : undefined,
        });
    });
    builder.addCase(finishTmThunk.pending, (state, action) => {
        state.nextOperationLoading = true;
    });
    builder.addCase(finishTmThunk.rejected, (state, rejectedValue) => {
        state.nextOperationLoading = false;
        notificationEmit({
            title: (rejectedValue.payload as any)?.data?.[0] ?? "Не удалось завершить",
            type: "error",
        });
    });
};
const stopTmBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(stopTmThunk.fulfilled, (state, action) => {
        state.startedTm = null;
        state.startedTms = state.startedTms.filter(
            (tm) => tm.id !== action.meta.arg.tmId
        );
        state.stopLoading = false;
        notificationEmit({
            title: "Процесс остановлен!",
            type: "success",
        });
    });
    builder.addCase(stopTmThunk.pending, (state, action) => {
        state.stopLoading = true;
    });
    builder.addCase(stopTmThunk.rejected, (state, rejectedValue) => {
        state.stopLoading = false;
        notificationEmit({
            title: (rejectedValue.payload as any)?.data?.[0] ?? "Не удалось остановить!",
            type: "error",
        });
    });
};
const attachItemBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(attachItemThunk.fulfilled, (state, action) => {
        const newItem = action.payload.data;
        if (state.startedOperation) {
            state.startedOperation.operation_items.push(newItem);
        }
        state.attachItemLoading = false;
    });
    builder.addCase(attachItemThunk.pending, (state, action) => {
        state.attachItemLoading = true;
    });
    builder.addCase(attachItemThunk.rejected, (state, rejectedValue) => {
        state.attachItemLoading = false;
        console.log("rejectedValue.payload", rejectedValue.payload);

        notificationEmit({
            title:
                (rejectedValue.payload as any)?.data?.data?.[0] ??
                "Не удалось прикрепить элемент",
            type: "error",
        });
    });
};
const detachItemBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(detachItemThunk.fulfilled, (state, action) => {
        if (state.startedOperation) {
            state.startedOperation.operation_items =
                state.startedOperation.operation_items.filter(
                    (oi) => oi.id !== action.payload.data.id
                );
        }
        state.detachItemLoading.filter((item) => item !== action.meta.arg.id);
    });
    builder.addCase(detachItemThunk.pending, (state, action) => {
        state.detachItemLoading.push(action.meta.arg.id);
    });
    builder.addCase(detachItemThunk.rejected, (state, action) => {
        state.detachItemLoading.filter((item) => item !== action.meta.arg.id);

        notificationEmit({
            title:
                (action.payload as any)?.data?.data?.[0] ??
                "Не удалось открепить элемент",
            type: "error",
        });
    });
};
const approveTmBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(approveTmThunk.fulfilled, (state, action) => {
        const newItem = action.payload.data;
        const level = action.meta.arg.level ?? 0;
        if (state.techMapsPaginated[level]) {
            state.techMapsPaginated[level].techMaps = (
                state.techMapsPaginated[level].techMaps as any
            ).map((item: any) => (item.id === newItem.id ? newItem : item));
        }

        // state.techMaps = state.techMaps.map((item) =>
        //     item.id === newItem.id ? newItem : item
        // );
        // state.techMapsForReview = state.techMapsForReview.map((item) =>
        //     item.id === newItem.id ? newItem : item
        // );
        delete state.approveTmLoading[action.meta.arg.tmtId];
    });
    builder.addCase(approveTmThunk.pending, (state, action) => {
        state.approveTmLoading[action.meta.arg.tmtId] = action.meta.arg.decision;
    });
    builder.addCase(approveTmThunk.rejected, (state, rejectedValue) => {
        delete state.approveTmLoading[rejectedValue.meta.arg.tmtId];

        notificationEmit({
            title:
                (rejectedValue.payload as any)?.data?.data?.[0] ?? "Не удалось запустить",
            type: "error",
        });
    });
};

const nextOperationBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(nextOperationThunk.fulfilled, (state, action) => {
        const newOperation = action.payload.data[0];
        if (state.startedTm) {
            state.startedTm = {
                ...state.startedTm,
                operations: [
                    ...state.startedTm.operations.map((operation) => {
                        return operation.id === state.startedOperation?.id
                            ? {
                                  ...operation,
                                  status: "successful" as TTechMapOperationStatus,
                              }
                            : operation;
                    }),
                    newOperation,
                ],
            };
        }
        state.startedOperation = newOperation;

        state.nextOperationLoading = false;
    });
    builder.addCase(nextOperationThunk.pending, (state, action) => {
        state.nextOperationLoading = true;
    });
    builder.addCase(nextOperationThunk.rejected, (state, rejectedValue) => {
        state.nextOperationLoading = false;
        const payload = rejectedValue.payload as any;
        const message = payload?.data?.message;
        if (message === "available count of not scannable less than required") {
            const ids = payload?.data?.data as number[];
            const items =
                state.startedOperation?.template_operation?.necessary_items.filter(
                    (item) => {
                        return ids.includes(item.id);
                    }
                ) ?? [];
            const itemLables = ids.map((id) => {
                const item = items.find((item) => item.id === id);
                if (item) {
                    return item.label;
                }
                return id;
            });
            notificationEmit({
                title: "Не удалось найти элементы:",
                description: itemLables.join(","),
                type: "error",
            });
            return;
        }
        notificationEmit({
            title: (rejectedValue.payload as any)?.data?.[0] ?? "Не удалось запустить",
            type: "error",
        });
    });
};
const pauseOperationBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(pauseOperationThunk.fulfilled, (state, action) => {
        const updatedOperation = action.payload.data;

        state.startedTms = state.startedTms.map((tm) => {
            if (tm.id === updatedOperation.technical_map_id)
                return {
                    ...tm,
                    spent_time: updatedOperation.technical_map.spent_time,
                    is_paused: true,
                };
            return tm;
        });

        if (state.startedTm) {
            state.startedTm = {
                ...state.startedTm,
                is_paused: true,
                operations: [
                    ...state.startedTm.operations.map((operation) => {
                        return operation.id === updatedOperation.id
                            ? {
                                  ...operation,
                                  spent_time: updatedOperation.spent_time,
                                  status: "paused" as TTechMapOperationStatus,
                              }
                            : operation;
                    }),
                ],
            };
        }
        if (state.startedOperation && state.startedOperation.id === updatedOperation.id) {
            state.startedOperation.status = "paused";
            state.startedOperation.spent_time = updatedOperation.spent_time;
        }

        state.pauseResumeLoading = false;
    });
    builder.addCase(pauseOperationThunk.pending, (state, action) => {
        state.pauseResumeLoading = true;
    });
    builder.addCase(pauseOperationThunk.rejected, (state, rejectedValue) => {
        state.pauseResumeLoading = false;
        notificationEmit({
            title:
                (rejectedValue.payload as any)?.data?.[0] ??
                "Не удалось поставить на паузу операцию",
            type: "error",
        });
    });
};
const resumeOperationBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(resumeOperationThunk.fulfilled, (state, action) => {
        const updatedOperation = action.payload.data;
        state.startedTms = state.startedTms.map((tm) => {
            if (tm.id === updatedOperation.technical_map_id)
                return {
                    ...tm,
                    is_paused: false,
                };
            return tm;
        });
        if (state.startedTm && state.startedTm.id === updatedOperation.technical_map_id) {
            state.startedTm = {
                ...state.startedTm,
                spent_time: updatedOperation.technical_map.spent_time,
                is_paused: false,
                operations: [
                    ...state.startedTm.operations.map((operation) => {
                        return operation.id === updatedOperation.id
                            ? {
                                  ...operation,
                                  spent_time: updatedOperation.spent_time,
                                  status: updatedOperation.status,
                              }
                            : operation;
                    }),
                ],
            };
        }
        if (state.startedOperation && state.startedOperation.id === updatedOperation.id) {
            state.startedOperation.status = updatedOperation.status;
            state.startedOperation.spent_time = updatedOperation.spent_time;
        }

        state.pauseResumeLoading = false;
    });
    builder.addCase(resumeOperationThunk.pending, (state, action) => {
        state.pauseResumeLoading = true;
    });
    builder.addCase(resumeOperationThunk.rejected, (state, rejectedValue) => {
        state.pauseResumeLoading = false;
        notificationEmit({
            title:
                (rejectedValue.payload as any)?.data?.[0] ??
                "Не удалось запустить операцию",
            type: "error",
        });
    });
};

export const techMapTemplateBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    executableTmtsLoadBuilder(builder);
    startTmtBuilder(builder);
    approveTmBuilder(builder);
    finishTmBuilder(builder);
    attachItemBuilder(builder);
    stopTmBuilder(builder);
    nextOperationBuilder(builder);
    detachItemBuilder(builder);
    resumeOperationBuilder(builder);
    pauseOperationBuilder(builder);
};
