import { StageSelect } from "@entities/Portal/Stage";
import { useEntityRowsActionsChangeStage } from "../model";
import { Button, Flex } from "antd";
import { CategorySelect } from "@entities/Portal/Category";

interface IEntityRowsActionsChangeStage {
    entityId: number;
    selectedRows: any[];
    onSuccess?: () => void;
    smallVersion?: boolean;
}

const EntityRowsActionsChangeStage = ({
    entityId,
    selectedRows,
    onSuccess,
    smallVersion,
}: IEntityRowsActionsChangeStage) => {
    const {
        stage,
        category,
        categories,
        isSingleCategory,
        disabled,
        loading,
        onChangeStage,
        onChangeCategory,
        onSubmit,
    } = useEntityRowsActionsChangeStage(entityId, selectedRows, onSuccess);
    return (
        <Flex style={{ flex: "1 1 100%" }} wrap="wrap" align="center">
            {!disabled && !isSingleCategory && (
                <div style={{ flex: "1 1 60%" }}>
                    <CategorySelect
                        smallVersion={smallVersion}
                        entityId={entityId}
                        multiple={false}
                        value={category}
                        onChangeValue={onChangeCategory}
                    />
                </div>
            )}
            {!disabled && (
                <div
                    style={{
                        flex: "1 1 60%",
                    }}
                >
                    <StageSelect
                        smallVersion={smallVersion}
                        entityId={entityId}
                        multiple={false}
                        value={stage}
                        onChangeValue={onChangeStage}
                        categoryValue={isSingleCategory ? categories[0] : category}
                    />
                </div>
            )}
            <Button
                type="link"
                onClick={onSubmit}
                disabled={disabled}
                style={{ padding: 0 }}
                loading={loading}
            >
                Перевести в стадию
            </Button>
        </Flex>
    );
};

export default EntityRowsActionsChangeStage;
