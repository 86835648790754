import { techMapModel } from "@entities/Production/TechMap";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useTMQrBarActions = () => {
    const dispatch = useDispatch<AppDispatch>();
    const startedTm = useSelector(techMapModel.selectStartedTm);
    const startedOperation = useSelector(techMapModel.selectStartedOperation);
    const templateItems = startedOperation?.template_operation.necessary_items ?? [];
    const operationItems = startedOperation?.operation_items ?? [];
    const isLast = startedOperation?.template_operation?.child_operations?.length === 0;
    const isFilled =
        startedOperation &&
        templateItems.filter((item) => item.type === "filling" && item.is_scannable)
            .length === operationItems.length;
    const readedCode = useRef("");
    const onClickNext = () => {
        if (!startedOperation || !startedTm) {
            notificationEmit({
                type: "warning",
                title: "Не выбран процесс!",
            });
            return;
        }
        if (!isFilled) {
            notificationEmit({
                type: "error",
                title: "Не заполнены необходимые компоненты",
            });
            return;
        }

        dispatch(
            !isLast
                ? techMapModel.nextOperationThunk({
                      tmoId: startedOperation.id,
                  })
                : techMapModel.finishTmThunk({
                      tmId: startedTm.id,
                  })
        );
    };

    const parseReadedCode = (code: string) => {
        if (code === "action:next-operation") {
            onClickNext();
            return;
        } else if (code.includes("action:start-tmt-")) {
            const tmtId = code.split("action:start-tmt-")[1];
            if (tmtId) {
                if (startedTm === null)
                    dispatch(techMapModel.startTmtThunk({ tmtId: Number(tmtId) }));
                else
                    notificationEmit({
                        type: "warning",
                        title: "Уже есть запущенная операция",
                    });
            }
            return;
        } else if (startedOperation) {
            dispatch(
                techMapModel.attachItemThunk({ oId: startedOperation.id, value: code })
            );
            return;
        }

        notificationEmit({
            type: "warning",
            title: "Неизвестный код!",
        });
    };
    const handler = (ev: KeyboardEvent) => {
        console.log(ev);

        if (ev.code === "AltLeft") {
            readedCode.current += ev.key;
        }
        if (ev.code === "Enter" && readedCode.current.length > 0) {
            parseReadedCode(readedCode.current);
            readedCode.current = "";
        }
    };
    useEffect(() => {
        document.addEventListener("keypress", handler);
        return () => {
            document.removeEventListener("keypress", handler);
        };
    }, [startedOperation?.id, isFilled, startedTm]);
};
