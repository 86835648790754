import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { getOperation } from "../../api";
import { finishTmThunk, nextOperationThunk } from "../TechMapApiThunk";
import { selectStartedOperation, selectStartedTm } from "../TechMapSelectors";
import { printCode } from "@shared/StickerPrinter/emits";

export const goToNextOperation =
    (count?: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const startedTm = selectStartedTm(getState());
        const startedOperation = selectStartedOperation(getState());
        const templateItems = startedOperation?.template_operation.necessary_items ?? [];
        const operationItems = startedOperation?.operation_items ?? [];
        const isLast =
            startedOperation?.template_operation?.child_operations?.length === 0;
        const isFilled =
            startedOperation &&
            templateItems.filter((item) => item.type === "filling" && item.is_scannable)
                .length === operationItems.length;

        if (!startedOperation || !startedTm) {
            notificationEmit({
                type: "warning",
                title: "Не выбран процесс!",
            });
            return;
        }
        if (!isFilled) {
            notificationEmit({
                type: "error",
                title: "Не заполнены необходимые компоненты",
            });
            return;
        }

        await dispatch(
            !isLast
                ? nextOperationThunk({
                      tmoId: startedOperation.id,
                  })
                : finishTmThunk({
                      tmId: startedTm.id,
                      count,
                  })
        );
        if (
            startedOperation.template_operation.code_templates &&
            startedOperation.template_operation.code_templates.length === 0
        )
            return;

        const response = await getOperation(startedOperation.id);
        const operation = response.data.data;
        const code = operation.codes?.[0];
        if (code) {
            printCode(code.value, "qr");
        }
    };
