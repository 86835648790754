import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { FormInputItem } from "@entities/Portal/Datatype";
import {
    VariableModifyModal,
    creatingVariable,
} from "@features/Portal/Variables/CreateUpdateVariables";
import { FormItem } from "@shared/ui/Form";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FormFieldsListCreator } from "../../FormFieldsListCreator";

interface IEditFormActionBuilder {
    disabled?: boolean;
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}

const EditFormActionBuilder = ({
    disabled,
    templateItem,
    bizProcTemplate,
    setItem,
}: IEditFormActionBuilder) => {
    const [editingVariable, setEditingVariable] = useState<TVariable | null>(null);
    const dispatch = useDispatch();
    const onChangeSelectedFields = (fields: TBizProcTemplateField[]) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                fields: fields.map((field: TBizProcTemplateField, index: number) => {
                    const createdField = templateItem.parameters?.fields?.find(
                        (item: any) => item.key === field.key
                    );
                    return {
                        unique_id: `${field.variable_type}-${field.key}`,
                        key: field?.key,
                        datatype: field?.datatype,
                        variable_type: field.variable_type,
                        related_entity_uuid: field.related_entity_uuid,
                        name: field.name,
                        is_required: createdField?.is_required ?? false,
                        is_readonly: createdField?.is_readonly ?? false,
                    };
                }),
            },
        });
    };
    const onChangeFields = (fields: TBizProcTemplateActionField[]) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                fields: fields.map(
                    (field: TBizProcTemplateActionField, index: number) => ({
                        unique_id: `${field.variable_type}-${field.key}`,
                        key: field?.key,
                        variable_type: field.variable_type,
                        related_entity_uuid: field.related_entity_uuid,
                        datatype: field?.datatype,
                        additional_parameters: field?.additional_parameters,
                        name: field.name,
                        is_required: field.is_required,
                        is_readonly: field.is_readonly,
                    })
                ),
            },
        });
    };
    const onClickCreateVariable = () => {
        setEditingVariable({
            ...creatingVariable,
            parent_type: "biz_proc_template",
            parent_id: bizProcTemplate.id,
        });
    };

    const onSuccess = (variable: TVariable) => {
        dispatch(
            bizProcTemplateModel.setEditingBizProcTemplateItem({
                ...bizProcTemplate,
                variables: [...(bizProcTemplate.variables ?? []), variable],
            })
        );
    };

    return (
        <FormItem label="Форма">
            <>
                <FormFieldsListCreator
                    disabled={disabled}
                    onClickCreateVariable={onClickCreateVariable}
                    onChangeSelectedFields={onChangeSelectedFields}
                    onChangeFields={onChangeFields}
                    labelKey="name"
                    valueKey="key"
                    selectedFields={templateItem.parameters?.fields ?? []}
                    fields={bizProcTemplate.fields ?? []}
                />
                <VariableModifyModal
                    onSuccess={onSuccess}
                    editingVariable={editingVariable}
                    formInputItem={(props: TFieldInput) => <FormInputItem {...props} />}
                />
            </>
        </FormItem>
    );
};

export default EditFormActionBuilder;
