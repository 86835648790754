import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Button, Modal, Spin, Tag } from "antd";
import { useStickerPrinter } from "../model";

interface IPrinterModule {
    onChangeConnectedState?: (state: boolean) => void;
}

const PrinterModule = ({ onChangeConnectedState }: IPrinterModule) => {
    const { printerState, connect } = useStickerPrinter(onChangeConnectedState);
    return (
        <>
            <Modal
                centered
                open={printerState.printing && printerState.isConnected}
                destroyOnClose
                width={200}
                footer={[]}
                closable={false}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "20px 0px",
                    }}
                >
                    <IndentContainer>
                        <Spin size="large" />
                    </IndentContainer>
                    <span>Код печается</span>
                </div>
            </Modal>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <IndentContainer>
                    <span>Принтер кодов</span>
                </IndentContainer>
                {printerState.isConnected ? (
                    <Tag color="success">Подключен</Tag>
                ) : (
                    <Button onClick={connect} loading={printerState.connecting}>
                        Подключить
                    </Button>
                )}
            </div>
        </>
    );
};

export default PrinterModule;
