import { useState } from "react";
import { getNewNavigationRoute } from "../lib";
import {
    navigationRouteApi,
    navigationRouteModel,
} from "@entities/Portal/NavigationRoute";
import { useDispatch } from "react-redux";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useSelector } from "react-redux";

export const useNavigationRouteEditUpdate = (
    navigationRoute?: TNavigationRoute | null,
    onSave?: () => void
) => {
    const routes = useSelector(navigationRouteModel.selectNavigationRoutes);
    const [editingNavigationRoute, setEditingNavigationRoute] =
        useState<TNavigationRoute>(
            navigationRoute ?? {
                ...getNewNavigationRoute(),
                order: routes.length,
            }
        );
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditingNavigationRoute({
            ...editingNavigationRoute,
            [event.target.name]: event.target.value,
        });
    };
    const onChangeType = (type: string) => {
        setEditingNavigationRoute({
            ...editingNavigationRoute,
            type: type as TNavigationRouteType,
        });
    };
    const onChangeBlank = (blank: number) => {
        setEditingNavigationRoute({
            ...editingNavigationRoute,
            blank: blank as TNavigationRouteBlank,
        });
    };
    const onSubmit = () => {
        setLoading(true);
        if (editingNavigationRoute.id) {
            navigationRouteApi
                .updateNavigationRoute(editingNavigationRoute)
                .then((response) => {
                    dispatch(navigationRouteModel.updateRoute(response.data.data));
                    onSave && onSave();
                })
                .catch((error) => {
                    notificationEmit({
                        error: error,
                        title: "Не удалось сохранить изменения",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            navigationRouteApi
                .createNavigationRoute(editingNavigationRoute)
                .then((response) => {
                    dispatch(navigationRouteModel.createRoute(response.data.data));
                    onSave && onSave();
                })
                .catch((error) => {
                    notificationEmit({
                        error: error,
                        title: "Не удалось создать элемент",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    return {
        editingNavigationRoute,
        loading,
        onChangeInput,
        onChangeType,
        onSubmit,
        onChangeBlank,
    };
};
