import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";

interface IEntitySelect {
    multiple?: boolean;
    disabled?: boolean;
    value: string | null;
    onChangeSelect: (
        value: string | null,
        item: { label: string; value: string; option: TEntity } | null
    ) => void;
}

const EntitySelect = ({ multiple, disabled, value, onChangeSelect }: IEntitySelect) => {
    return (
        <PaginatedSelect
            onlyRead={disabled}
            multiple={multiple}
            requestUrl={`${apiUrl}/api/entity?idKey=uuid`}
            value={value}
            storeName="entitytable"
            valueKey="uuid"
            labelKey="name"
            onChangeValue={onChangeSelect}
            initialOptions={[]}
        />
    );
};

export default EntitySelect;
