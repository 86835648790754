import { databases } from "./constants";

let version = 1;

const onupgradeneeded = (store: TDatabaseStore, request: IDBOpenDBRequest) => {
    databases[store.name] = request.result;

    store.tables.forEach((table) => {
        databases[store.name].createObjectStore(table.name, {
            keyPath: table.keyPath,
        });
    });
};

const dbOpenRequest = (
    storeName: string,
    onupgradeneeded?: (request: IDBOpenDBRequest) => void
): Promise<IDBOpenDBRequest> => {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            reject(null);
        }, 50);
        let request = indexedDB.open(storeName, version);
        request.onupgradeneeded = () => {
            onupgradeneeded?.(request);
            resolve(request);
            clearTimeout(timeout);
        };

        request.onsuccess = (e) => {
            resolve(request);
            clearTimeout(timeout);
        };

        request.onerror = (e) => {
            reject(null);
            clearTimeout(timeout);
        };
        request.onblocked = (e) => {
            reject(null);
            clearTimeout(timeout);
        };
    });
};

export const dbInitStore = (store: TDatabaseStore): Promise<boolean | IDBDatabase> => {
    return new Promise(async (resolve) => {
        if (databases[store.name]) {
            resolve(databases[store.name]);
        }
        try {
            const request = await dbOpenRequest(store.name, (request) =>
                onupgradeneeded(store, request)
            );
            databases[store.name] = request.result;
            resolve(true);
        } catch {
            resolve(false);
        }
    });
};
export const dbDropStores = (notDropingStores?: string[]) => {
    indexedDB.databases().then((databases) => {
        databases.forEach(({ name }) => {
            if (!notDropingStores?.includes(name!)) dbDropStore(name!);
        });
    });
};
export const dbClearTableInAllStores = (table: string, notDropingStores?: string[]) => {
    Object.keys(databases).forEach((storeName) => {
        if (!notDropingStores?.includes(storeName)) {
            const tx = databases[storeName].transaction(table, "readwrite");
            const store = tx.objectStore(table);
            store.clear();
        }
    });
};
export const dbDropStore = (storeName: string) => {
    return new Promise((resolve, reject) => {
        var req = indexedDB.deleteDatabase(storeName);
        req.onsuccess = function () {
            delete databases[storeName];
            resolve(true);
        };
        req.onerror = function () {
            resolve(false);
            console.log("Couldn't delete database");
        };
        req.onblocked = function () {
            resolve(false);
            console.log("Couldn't delete database due to the operation being blocked");
        };
    });
};
export const dbAddData = <T>(
    storeName: string,
    tableName: string,
    data: T
): Promise<T | string | null> => {
    return new Promise((resolve) => {
        let request = indexedDB.open(storeName, version);

        request.onsuccess = () => {
            databases[storeName] = request.result;
            const tx = databases[storeName].transaction(tableName, "readwrite");
            const store = tx.objectStore(tableName);
            store.add(data);
            resolve(data);
        };

        request.onerror = () => {
            const error = request.error?.message;
            if (error) {
                resolve(error);
            } else {
                resolve("Unknown error");
            }
        };
    });
};

export const dbDeleteData = (
    storeName: string,
    tableName: string,
    key: string
): Promise<boolean> => {
    return new Promise((resolve) => {
        let request = indexedDB.open(storeName, version);

        request.onsuccess = () => {
            databases[storeName] = request.result;
            const tx = databases[storeName].transaction(tableName, "readwrite");
            const store = tx.objectStore(tableName);
            const res = store.delete(key);
            res.onsuccess = () => {
                resolve(true);
            };
            res.onerror = () => {
                resolve(false);
            };
        };
    });
};

export const dbUpdateData = <T>(
    storeName: string,
    tableName: string,
    key: string,
    data: T
): Promise<T | string | null> => {
    return new Promise((resolve) => {
        let request = indexedDB.open(storeName, version);

        request.onsuccess = () => {
            databases[storeName] = request.result;
            const tx = databases[storeName].transaction(tableName, "readwrite");
            const store = tx.objectStore(tableName);
            const res = store.get(key);
            res.onsuccess = () => {
                const newData = { ...res.result, ...data };
                store.put(newData);
                resolve(newData);
            };
            res.onerror = () => {
                resolve(null);
            };
        };
    });
};
export const dbPutData = <T>(
    storeName: string,
    tableName: string,
    data: T
): Promise<T | string | null> => {
    return new Promise((resolve) => {
        let request = indexedDB.open(storeName, version);

        request.onsuccess = () => {
            databases[storeName] = request.result;
            const tx = databases[storeName].transaction(tableName, "readwrite");
            const store = tx.objectStore(tableName);
            store.put(data);
            resolve(null);
        };

        request.onblocked = function () {
            resolve(null);
            console.error("onblocked dbPutData", tableName, storeName);
        };
        request.onerror = function () {
            resolve(null);
            console.error("onerror dbPutData", tableName, storeName);
        };
    });
};
export const dbPutDataArray = <T>(
    storeName: string,
    tableName: string,
    data: T[]
): Promise<T | string | null> => {
    return new Promise((resolve) => {
        let request = indexedDB.open(storeName, version);

        request.onsuccess = () => {
            databases[storeName] = request.result;
            const tx = databases[storeName].transaction(tableName, "readwrite");
            const store = tx.objectStore(tableName);
            data.forEach((item) => {
                store.put(item);
            });
            resolve(null);
        };
        request.onblocked = function () {
            resolve(null);
            console.error("onblocked dbPutDataArray", tableName, storeName);
        };
        request.onerror = function () {
            resolve(null);
            console.error("onerror dbPutDataArray", tableName, storeName);
        };
    });
};

export const dbGetStoreData = <T>(
    storeName: string,
    tableName: string,
    query?: IDBValidKey | IDBKeyRange | null
): Promise<T[]> => {
    return new Promise((resolve) => {
        let request = indexedDB.open(storeName, version);

        request.onsuccess = () => {
            databases[storeName] = request.result;
            const tx = databases[storeName].transaction(tableName, "readonly");
            const store = tx.objectStore(tableName);
            const res = store.getAll(query);
            res.onsuccess = () => {
                resolve(res.result);
            };
            res.onerror = () => {
                resolve([]);
            };
        };

        request.onblocked = function () {
            resolve([]);
            console.error("onblocked dbGetStoreData", tableName, storeName);
        };
        request.onerror = function () {
            resolve([]);
            console.error("onerror dbGetStoreData", tableName, storeName);
        };
    });
};
export const dbGetStoreDataItem = <T>(
    storeName: string,
    tableName: string,
    id: number | string
): Promise<T[]> => {
    return new Promise((resolve) => {
        let request = indexedDB.open(storeName, version);

        request.onsuccess = () => {
            databases[storeName] = request.result;
            const tx = databases[storeName].transaction(tableName, "readonly");
            const store = tx.objectStore(tableName);
            const res = store.get(id);
            res.onsuccess = () => {
                resolve(res.result);
            };
            res.onerror = () => {
                resolve([]);
            };
        };

        request.onerror = function () {
            resolve([]);
            console.log("Couldn't delete database");
        };
        request.onblocked = function () {
            resolve([]);
            console.log("dbGetStoreDataItem operation being blocked");
        };
    });
};
