import { Code } from "@shared/ui/Code";
import { Input } from "antd";
import { ChangeEvent } from "react";

interface IBarCodeInput {
    value: any;
    onlyRead?: boolean;
    onChangeValue: (value: any) => void;
    disabled?: boolean;
}

const BarCodeInput = ({ value, onlyRead, onChangeValue, disabled }: IBarCodeInput) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChangeValue({
            value: event.target.value === "" ? null : event.target.value,
        });
    };
    if (onlyRead) {
        return value?.value ? (
            <Code
                codeType="barcode"
                renderer="canvas"
                height={40}
                width={1}
                fontSize={12}
                value={value.value}
                showValue
            />
        ) : (
            <>-</>
        );
    }
    return <Input disabled={disabled} value={value?.value} onChange={onChange} />;
};

export default BarCodeInput;
