import { datatypeModel } from "@entities/Portal/Datatype";
import { EntityFieldItemList } from "@features/Portal/Entity/EntityFieldItemList";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { UpdateCreateEntityField } from "@widgets/Portal/Entity/EntityFieldUpdating";
import { Modal, Typography } from "antd";
import { useSelector } from "react-redux";
import { useEntityModifyModal } from "../model/UseEntityModifyModal";
import CreateUpdateEntityForm from "./CreateUpdateEntityForm";

const EntityModifyModal = () => {
    const { editingEntity, isOpen, windowHeight, windowWidth, onClose } =
        useEntityModifyModal();
    const datatypes = useSelector(datatypeModel.selectDatatypes);

    return (
        <>
            <Modal
                title={`${editingEntity?.is_creating ? "Создание" : "Редактирование"} сущности`}
                open={isOpen}
                onCancel={onClose}
                destroyOnClose
                width={"90%"}
                footer={[]}
                style={{
                    top: windowHeight <= 700 ? "10%" : undefined,
                    padding: windowWidth <= 700 ? 0 : undefined,
                }}
            >
                <IndentContainer>
                    {editingEntity && (
                        <CreateUpdateEntityForm
                            onCancel={onClose}
                            onSave={onClose}
                            editingEntity={editingEntity}
                            entityId={editingEntity.id}
                        />
                    )}
                </IndentContainer>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                    Настройка полей
                </Typography.Title>

                <IndentContainer>
                    <UpdateCreateEntityField
                        datatypes={datatypes}
                        entityId={editingEntity?.id}
                    />
                </IndentContainer>
                <EntityFieldItemList entityUuid={editingEntity?.uuid} view="card" />
            </Modal>
        </>
    );
};

export default EntityModifyModal;
